import React from "react";
import MaskedInput from "react-text-mask";

function PhoneInput(props) {
  const underlinedMask = "+7 (___) ___-__-__";

  function handlerOnClick() {
    var inp = document.getElementById("phinp");
    if (!inp || inp.value === "") {
      inp.value = underlinedMask;
      inp.setSelectionRange(4, 4);
    }
  }

  function handlerOnBlur() {
    var inp = document.getElementById("phinp");
    if (inp.value === underlinedMask) inp.value = "";
  }

  return (
    <MaskedInput
      key="input-phone"
      name={props.name}
      id="phinp"
      type="text"
      className={props.className + " input-phone"}
      placeholder="+7 (000) 000-00-00"
      onClick={handlerOnClick}
      onBlur={handlerOnBlur}
      mask={[
        "+",
        "7",
        " ",
        "(",
        /[9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
      ]}
    />
  );
}

export default PhoneInput;
