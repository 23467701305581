function Logo(props) {
  if (props.t === "text") {
    return (
      <svg viewBox="0 0 205 58" xmlns="http://www.w3.org/2000/svg">
        <g id="fontsvg1698336627124" strokeLinecap="round" fillRule="evenodd">
          <path
            d="M 86.4 0.801 L 83.2 0.801 L 77.36 26.561 L 74.96 42.081 L 72.56 26.561 L 66.72 0.801 L 63.52 0.801 L 63.52 56.801 L 66.72 56.801 L 66.72 20.721 L 66.32 10.241
      L 73.36 46.321 L 76.56 46.321 L 83.6 10.241 L 83.2 20.721 L 83.2 56.801 L 86.4 56.801 L 86.4 0.801 Z M 185.28 0.801 L 182.08 0.801 L 176.24 26.561 L 173.84 42.081 
      L 171.44 26.561 L 165.6 0.801 L 162.4 0.801 L 162.4 56.801 L 165.6 56.801 L 165.6 20.721 L 165.2 10.241 L 172.24 46.321 L 175.44 46.321 L 182.48 10.241 L 182.08 20.721 
      L 182.08 56.801 L 185.28 56.801 L 185.28 0.801 Z M 37.92 56.801 L 30.88 33.201 C 34.246 32.36 35.953 29.797 36.322 25.674 A 20.547 20.547 0 0 0 36.4 23.841 L 36.4 10.321 
      A 16.881 16.881 0 0 0 36.047 6.687 C 35.351 3.547 33.601 1.603 30.589 1.002 A 11.184 11.184 0 0 0 28.4 0.801 L 20.8 0.801 L 20.8 56.801 L 24 56.801 L 24 33.441 
      L 27.6 33.441 L 34.64 56.801 L 37.92 56.801 Z M 146 56.801 L 146 53.681 L 136 53.681 L 136 30.321 L 144.4 30.321 L 144.4 27.281 L 136 27.281 L 136 3.921 L 146 3.921 
      L 146 0.801 L 132.8 0.801 L 132.8 56.801 L 146 56.801 Z M 204.88 56.801 L 204.88 53.681 L 194.88 53.681 L 194.88 30.321 L 203.28 30.321 L 203.28 27.281 L 194.88 27.281 
      L 194.88 3.921 L 204.88 3.921 L 204.88 0.801 L 191.68 0.801 L 191.68 56.801 L 204.88 56.801 Z M 122 56.801 L 122 3.921 L 129.2 3.921 L 129.2 0.801 L 111.6 0.801 L 111.6 3.921 
      L 118.8 3.921 L 118.8 56.801 L 122 56.801 Z M 3.2 56.801 L 3.2 36.561 L 8 36.561 A 9.759 9.759 0 0 0 11.274 36.058 C 14.393 34.95 15.857 32.046 15.99 27.636 
      A 22.433 22.433 0 0 0 16 26.961 L 16 10.321 A 16.881 16.881 0 0 0 15.647 6.687 C 14.951 3.547 13.201 1.603 10.189 1.002 A 11.184 11.184 0 0 0 8 0.801 L 0 0.801 L 0 56.801 
      L 3.2 56.801 Z M 57.52 48.801 L 57.52 8.721 A 14.221 14.221 0 0 0 57.154 5.324 C 56.467 2.544 54.788 0.812 51.928 0.225 A 11.998 11.998 0 0 0 49.52 0.001 
      C 43.92 0.001 41.52 3.041 41.52 8.721 L 41.52 48.801 C 41.52 53.626 43.252 56.604 47.205 57.391 A 11.864 11.864 0 0 0 49.52 57.601 C 53.843 57.601 56.259 55.742 
      57.136 52.282 A 14.227 14.227 0 0 0 57.52 48.801 Z M 108.4 48.801 L 108.4 8.721 A 14.221 14.221 0 0 0 108.034 5.324 C 107.347 2.544 105.668 0.812 102.808 0.225 A 
      11.998 11.998 0 0 0 100.4 0.001 C 94.8 0.001 92.4 3.041 92.4 8.721 L 92.4 48.801 C 92.4 53.626 94.132 56.604 98.085 57.391 A 11.864 11.864 0 0 0 100.4 57.601 C 
      104.723 57.601 107.139 55.742 108.016 52.282 A 14.227 14.227 0 0 0 108.4 48.801 Z M 44.72 48.801 L 44.72 8.721 C 44.72 5.041 46.16 3.041 49.52 3.041 A 6.03 6.03 0 0 
      1 51.454 3.325 C 53.359 3.969 54.245 5.695 54.315 8.37 A 13.422 13.422 0 0 1 54.32 8.721 L 54.32 48.801 A 10.059 10.059 0 0 1 54.095 51.042 C 53.663 52.921 52.596 
      54.099 50.768 54.45 A 6.613 6.613 0 0 1 49.52 54.561 C 46.16 54.561 44.72 52.481 44.72 48.801 Z M 95.6 48.801 L 95.6 8.721 C 95.6 5.041 97.04 3.041 100.4 3.041 
      A 6.03 6.03 0 0 1 102.334 3.325 C 104.239 3.969 105.125 5.695 105.195 8.37 A 13.422 13.422 0 0 1 105.2 8.721 L 105.2 48.801 A 10.059 10.059 0 0 1 104.975 51.042 
      C 104.543 52.921 103.476 54.099 101.648 54.45 A 6.613 6.613 0 0 1 100.4 54.561 C 97.04 54.561 95.6 52.481 95.6 48.801 Z M 8 33.521 L 3.2 33.521 L 3.2 3.841 L 8 
      3.841 A 5.154 5.154 0 0 1 10.096 4.236 C 11.969 5.062 12.8 7.161 12.8 10.321 L 12.8 26.961 C 12.8 30.844 11.546 33.194 8.646 33.489 A 6.366 6.366 0 0 1 8 33.521 
      Z M 28.4 30.401 L 24 30.401 L 24 3.841 L 28.4 3.841 A 5.154 5.154 0 0 1 30.496 4.236 C 32.369 5.062 33.2 7.161 33.2 10.321 L 33.2 23.841 C 33.2 27.724 31.946 
      30.074 29.046 30.369 A 6.366 6.366 0 0 1 28.4 30.401 Z"
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 1000 1000"
      >
        <g
          transform="matrix(0 -40.0481 -40.0481 0 500.0001 499.9998)"
          id="619650"
        >
          <path
            vectorEffect="non-scaling-stroke"
            transform=" translate(-11.35, -10.964)"
            d="M 1.056 21.928 c 0 -6.531 5.661 -9.034 10.018 -9.375 V 18.1 L 22.7 9.044 L 11.073 0 V 4.836 A 10.5 10.5 0 0 0 3.729 8.188 C -0.618 12.946 -0.008 21 0.076 21.928 Z"
            strokeLinecap="round"
          />
        </g>
      </svg>
    );
  }
}

export default Logo;
