import React from "react";
import AddressInput from "../inputs/adrInput";
import PhoneInput from "../inputs/phoneInput";
import DigitalMaskedInput from "../inputs/digitalMaskedInput";
import { Link } from "react-router-dom";

function Input({ input, errors, cookies, toggleFun, hide }) {
  switch (input.type) {
    case "radio":
      return (
        <>
          <label className={errors.includes(input.name) ? "input-error" : ""}>
            {input.label}
          </label>
          {input.options.map((option) => (
            <div key={"radio-" + input.name + "-" + option.name}>
              <input
                type={input.type}
                name={input.name}
                value={option.name}
                id={input.name + "-" + option.name}
              />
              <label htmlFor={input.name + "-" + option.name}>
                {option.text}
              </label>
            </div>
          ))}
        </>
      );

    case "address":
      return (
        <div className={hide ? "hide" : ""}>
          <label>{input.label}</label>
          <AddressInput
            name={input.name}
            className={errors.includes(input.name) ? "input-error" : ""}
          />
        </div>
      );
    case "phone":
      return (
        <>
          <label>{input.label}</label>
          <PhoneInput
            className={errors.includes(input.name) ? "input-error" : ""}
            name={input.name}
          />
        </>
      );
    case "date":
      return (
        <>
          <div>
            <label>{input.label}</label>
          </div>
          <input
            type={input.type}
            name={input.name}
            className={errors.includes(input.name) ? "input-error" : ""}
          />
        </>
      );
    case "masked":
      return (
        <>
          <label>{input.label}</label>
          <DigitalMaskedInput
            mask={input.mask}
            type={input.type}
            name={input.name}
            className={errors.includes(input.name) ? "input-error" : ""}
          />
        </>
      );
    case "checkbox":
      return (
        <div
          className={errors.includes(input.name) ? "input-error" : ""}
          onChange={() => (input.name === "adrSame" ? toggleFun() : null)}
        >
          <input
            type={input.type}
            name={input.name}
            id={input.name}
            defaultChecked={false}
            defaultValue={input.value ? input.value : ""}
          />
          <label htmlFor={input.name}>{input.label}</label>
          <br />
          <Link to={input.linkUrl} target="_blank">
            {input.linkLabel}
          </Link>
        </div>
      );

    case "hidden":
      return (
        <>
          <input
            type={input.type}
            name={input.name}
            value={cookies[input.name]}
          />
        </>
      );

    case "submit":
      return <button>{input.value ? input.value : "Отправить"}</button>;

    default:
      return (
        <>
          <input
            type={input.type}
            name={input.name}
            placeholder={input.label}
            defaultValue={input.value ? input.value : ""}
            className={errors.includes(input.name) ? "input-error" : ""}
          />
        </>
      );
  }
}

export default Input;
