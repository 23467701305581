import Form from "../../forms/form";
import login from "../../forms/login.json";

function Login() {
  return (
    <div id="login">
      <Form structure={login} />
    </div>
  );
}

export default Login;
