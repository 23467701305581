import "../../../styles/manager.css";

function Menu(props) {
  return (
    <div className="manager-menu">
      <ul>
        {props.structure.map((e) => (
          <a href={e.link} key={"mm-a-" + e.link}>
            <li>{e.label}</li>
          </a>
        ))}
      </ul>
    </div>
  );
}

export default Menu;
