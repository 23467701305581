import React from "react";
import "../styles/LangMenu.css";

function LangMenu() {
  const langs = ["RU", "EN", "ES"];
  const currentPath = window.location.pathname.split("/");

  function changeUrl(url) {
    if (currentPath.length >= 2) {
      currentPath[1] = url;
      return currentPath.join("/");
    }
    return "/";
  }

  return (
    <div className="lang-menu">
      <ul>
        {langs.map((l) => (
          <li key={"lang-menu-li-" + l}>
            <a href={changeUrl(l)}>{l}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LangMenu;
