import { useEffect } from "react";
import SETTINGS from "../settings/settings.json";

function RedirectMain() {
  useEffect(() => {
    function detectUserLanguage() {
      const detectedLanguage =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;
      if (detectedLanguage.startsWith("es")) {
        return "es";
      }
      if (detectedLanguage.startsWith("ru")) {
        return "ru";
      }
      return "en"; // Default to English if no match is found
    }

    const userLanguage = detectUserLanguage();
    window.location.replace(SETTINGS.SITE_URL + userLanguage + "/");
  }, []);

  return null;
}

export default RedirectMain;
