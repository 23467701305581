import SETTINGS from "../settings.json";
import React from "react";
import { Link } from "react-router-dom";

function Seo() {
  return (
    <div className="main-menu">
      <div className="container">
        {SETTINGS.MAIN_MENU.map((el, index) => {
          return (
            <Link to={el.href} key={index + "asdasd"}>
              <div key={index + el.name}>{el.name}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Seo;
