import React from "react";
import Footer from "../../footer";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import CONST from "../../settings.json";
import SRV from "../../../settings/settings.json";
import Menu from "./menu";
import Header from "../header";

function Page() {
  const [data, setData] = useState({
    loading: true,
    client: {},
  });
  const [profile] = useState({});
  const [cookies] = useCookies(["login_token", "role"]);
  useEffect(() => {
    if (Object.keys(profile).length == 0) {
      const resp = axios
        .post(
          SRV.SERVER_URL,
          {
            page: "clientDataRequest",
            request: "['main']",
            login_token: cookies["login_token"],
          },
          CONST.HEADERS
        )
        .then((response) => {
          if (response.data.errors.includes("LOGIN_TOKEN_ERROR")) {
            console.log("LOGIN_TOKEN_ERROR, redirecting..");
            window.location.replace(SRV.SITE_URL + "ru/login");
          }
          console.log(response.data);
          setData(response.data);
        });
    }
  }, []);

  if (!Object.keys(cookies).includes("login_token")) {
    window.location.replace(SRV.SITE_URL + "login");
  }

  return (
    <div className="page">
      <Header />
      <div className="content-manager">
        <Menu structure={CONST.CLIENT_MENU} />
        <div className="container-block container-block-manager">
          <div className="block">
            <p>{data.client.name}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Page;
