function Seo() {
  return (
    <div id="seo">
      <h1>Поисковая оптимизация (SEO)</h1>
      <p>
        Мы знаем, как заставить поисковые системы влюбиться в ваш сайт. Наши
        эксперты разрабатывают стратегии SEO, которые улучшают видимость вашего
        сайта и приводят к росту органического трафика.
      </p>

      <h2>Опыт и экспертиза</h2>
      <p>
        Мы успешно продвигаем сайты уже много лет и знаем, как достичь
        результатов. Наши эксперты оснащены знаниями и опытом для эффективной
        SEO-стратегии под вашу индивидуальную нишу.
      </p>

      <h2>Индивидуальный подход</h2>
      <p>
        ы разрабатываем стратегии, учитывая особенности вашего бизнеса и вашей
        аудитории. Мы понимаем, что нет двух одинаковых компаний, поэтому ваша
        стратегия SEO будет уникальной.
      </p>

      <h2>Оптимизация для роста</h2>
      <p>
        Наша цель - не просто привлечь трафик, но и превратить его в конверсии.
        Мы используем долгосрочные стратегии, которые способствуют устойчивому
        росту вашего онлайн-присутствия и прибыли.
      </p>
    </div>
  );
}

export default Seo;
