import React from "react";
import { Route, Routes } from "react-router-dom";
import RuPage from "./components/ru/page";
import EnMain from "./components/en/EnMain";
import EsMain from "./components/es/EsMain";
import RedirectMain from "./components/RedirectMain";
import Client from "./components/ru/private/client";
import ClientJobs from "./components/ru/private/clientJobs";
import Manager from "./components/ru/private/manager";
import LogOut from "./components/ru/private/logOut";

function App() {
  return (
    <Routes>
      <Route path="/" element={<RedirectMain />} />
      <Route exact path="/logout" element={<LogOut />} />
      <Route path="/ru" element={<RuPage content="main" />}></Route>
      <Route exact path="/ru/client" element={<Client />} />
      <Route exact path="/ru/client/jobs" element={<ClientJobs />} />
      <Route exact path="/ru/manager" element={<Manager />} />
      <Route exact path="/logout" element={<Manager />} />
      <Route
        path="/ru/razrabotka_saitov"
        element={<RuPage content="sites" />}
      />
      <Route
        path="/ru/kontekstnaya_reklama"
        element={<RuPage content="context" />}
      />
      <Route
        path="/ru/poiskovaya_optimizatsiya_seo"
        element={<RuPage content="seo" />}
      />
      <Route exact path="/ru/login" element={<RuPage content="login" />} />

      <Route path="/en/*" element={<EnMain content="ssss" />}></Route>
      <Route path="/es/*" element={<EsMain />}></Route>
    </Routes>
  );
}

export default App;
