import React from "react";
import Header from "./header";
import MainMenu from "./mainMenu";
import Footer from "../footer";
import { Helmet } from "react-helmet";
import ScrollToHashElement from "../ScrollToHashElement";
import * as Pages from "./pages/all_pages";

function Page(props) {
  return (
    <div className="page">
      <Helmet>
        <html lang="ru"></html>
        <title>PROMOTE ME - продвижение сайтов</title>
        <meta name="description" content="продвижение сайтов" />
        <meta name="keywords" content="продвижение, сайтов, SEO" />
      </Helmet>
      <ScrollToHashElement />

      <Header />
      <MainMenu />
      {props.content && (
        <div className="container">
          {(() => {
            const Component = Pages[props.content];
            return <Component />;
          })()}
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Page;
