import React, { useState } from "react";

const Slider = ({ min, max, step, value, onChange }) => {
  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    onChange(newValue);
  };

  return (
    <div>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        style={{
          backgroundSize: `${((value - min) / (max - min)) * 94 + 3}% 100%`,
        }}
      />
    </div>
  );
};

export default Slider;
