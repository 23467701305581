import React from "react";
import LangMenu from "./LangMenu";

function Header() {
  return (
    <div className="footer">
      <div className="copyright">
        &copy; {new Date().getFullYear()} Promote Me
      </div>
      <LangMenu />
    </div>
  );
}

export default Header;
