import React, { useState, useEffect } from "react";
import Form from "../../forms/form";
import clientRegister from "../../forms/clientRegister.json";
import PriceAdjust from "../../priceAdjust";

function Main() {
  const [price, setPrice] = useState(35000);
  const [needSite, setNeedSite] = useState(false);
  const [needSeo, setNeedSeo] = useState(true);
  const [needContext, setNeedContext] = useState(true);
  const [monthly, setMonthly] = useState([]);
  const [atFirst, setAtFirst] = useState([]);

  useEffect(() => {
    // Calculate the total whenever any relevant state changes
    const calculateTotal = () => {
      const seoToContextRatio = 0.4;
      const priceForArticle = 5000;
      let contextAcc = 0;
      let context = 0;
      let contextTotal = 0;
      let seoTotal = 0;
      let articles = 0;
      let links = 0;
      let newAtFirst = [];
      let newMonthly = [];

      if (needContext & !needSeo) {
        contextTotal = price;
      }

      if (needContext & needSeo) {
        seoTotal = Math.round((price * seoToContextRatio) / 100) * 100;
        contextTotal = price - seoTotal;
      }

      if (!needContext & needSeo) {
        seoTotal = price;
      }

      if (needContext) {
        context = Math.round((5000 + (contextTotal - 5000) * 0.05) / 100) * 100;
        contextAcc = contextTotal - context;
        newMonthly.push({
          name: "contextAcc",
          label: "Пополнение рекламного счета",
          price: contextAcc,
        });
        newMonthly.push({
          name: "context",
          label: "Поддержка и ребалансировка стратегии",
          price: context,
        });
      }

      if (needSite) {
        newAtFirst.push({
          name: "site",
          label: "Разработка сайта, оптимизированного для продвижения",
          price: 49000,
        });
      }
      if (needSeo & !needSite) {
        newAtFirst.push({
          name: "firstSeo",
          label: "Оптимизация сайта",
          price: 11900,
        });
      }
      if (needSeo) {
        if (seoTotal > priceForArticle) {
          articles =
            Math.round((seoTotal * 0.65) / priceForArticle) * priceForArticle;
        } else {
          articles = 0;
        }
        links = seoTotal - articles;
        newMonthly.push({
          name: "articles",
          label: "Написание регулярных статей для сайта",
          price: articles,
        });
        newMonthly.push({
          name: "links",
          label: "Закупка ссылок",
          price: links,
        });
        newAtFirst.push({
          name: "seoNew",
          label: "Разработка рекламной стратегии",
          price: 9000,
        });
      }
      setAtFirst(newAtFirst);
      setMonthly(newMonthly);
    };

    calculateTotal();
  }, [price, needSite, needSeo, needContext]);

  const handlePriceChange = (newValue) => {
    setPrice(newValue);
  };

  var even = false;
  function switch_row() {
    even = !even;
    return even ? "even" : "";
  }

  return (
    <>
      <div className="price-adjust">
        <div>
          <input
            id="needSite"
            type="checkbox"
            checked={needSite}
            onChange={() => setNeedSite(!needSite)}
          />
          <label for="needSite">Мне нужен сайт</label>
        </div>
        <div>
          <input
            id="needSeo"
            type="checkbox"
            checked={needSeo}
            onChange={() => {
              setNeedSeo(!needSeo);
            }}
          />
          <label for="needSeo">Мне нужна поисковая оптимизация</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="needContext"
            checked={needContext}
            onChange={() => {
              setNeedContext(!needContext);
            }}
          />
          <label for="needContext">Мне нужна контекстная реклама</label>
        </div>
        <p>Сколько вы готовы вкладывать в продвижение сайта в месяц:</p>
        <PriceAdjust
          min={18000}
          max={100000}
          step={1000}
          value={price}
          onChange={handlePriceChange}
        />
        <div className="price"> {price} </div>
        <div className="price-results">
          <p>Ежемесячные расходы</p>
          <table>
            {monthly.map((m) => (
              <tr className={switch_row()}>
                <td
                  className="price-results-label"
                  style={{ lineHeight: `${m.price / 300}px` }}
                >
                  {m.label}
                </td>
                <td
                  className="price-results-price"
                  style={{ lineHeight: `${m.price / 300}px` }}
                >
                  {m.price}
                </td>
              </tr>
            ))}
          </table>
          <p>Разовые расходы</p>
          <table>
            {atFirst.map((e) => (
              <tr className={switch_row()}>
                <td
                  className="price-results-label"
                  style={{ lineHeight: `${e.price / 300}px` }}
                >
                  {e.label}
                </td>
                <td
                  className="price-results-price"
                  style={{ lineHeight: `${e.price / 300}px` }}
                >
                  {e.price}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
      <Form structure={clientRegister} />
    </>
  );
}

export default Main;
