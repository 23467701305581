import React from "react";
import LangMenu from "../LangMenu";
import Logo from "../logo";
import { Helmet } from "react-helmet";

function RuMain() {
  return (
    <div className="page">
      <Helmet>
        <html lang="es"></html>
        <title>PROMOTE ME - Promoción de Sitios Web</title>
        <meta name="description" content="Promoción de sitios web" />
        <meta name="keywords" content="promoción, sitios web, SEO" />
      </Helmet>
      <div className="container">
        <div className="logo">
          <Logo t="img" />
          <Logo t="text" />
        </div>
        <h1>¡Bienvenido a PROMOTE ME!</h1>

        <p>Somos tu socio confiable en el mundo de la promoción digital.</p>
        <p>
          Nuestra misión es ayudar a que tu sitio web alcance la cima en los
          motores de búsqueda, atraiga una gran cantidad de visitantes y aumente
          tus ganancias en línea.
        </p>
        <h2>Lo que hacemos:</h2>
        <h3>Optimización para Motores de Búsqueda (SEO)</h3>
        <p>
          Sabemos cómo hacer que los motores de búsqueda se enamoren de tu sitio
          web. Nuestros expertos desarrollan estrategias de SEO que mejoran la
          visibilidad de tu sitio y aumentan el tráfico orgánico.
        </p>
        <h3>Publicidad de Pago por Clic</h3>
        <p>
          Resultados instantáneos para tus campañas publicitarias son nuestra
          especialidad. Creamos y optimizamos campañas publicitarias en Google
          Ads y Yandex.Direct para atraer a aquellos que buscan tu producto o
          servicio.
        </p>
        <h3>Redes Sociales</h3>
        <p>
          No solo administramos tus cuentas en redes sociales; creamos contenido
          atractivo y estrategias que fidelizan a tu audiencia y la mantienen
          activa.
        </p>
        <h3>Marketing de Contenido</h3>
        <p>
          El contenido es fundamental. Nuestros escritores y diseñadores
          creativos crean contenido que llama la atención y cuenta tu historia.
        </p>
        <h3>Análisis e Informes</h3>
        <p>
          Trabajamos en base a datos. Nuestros analistas siguen y analizan los
          resultados para asegurar una mejora constante de tu estrategia en
          línea.
        </p>

        <h2>¿Por qué PROMOTE ME?</h2>
        <ul>
          <li>
            Experiencia: Hemos promocionado sitios web con éxito durante muchos
            años y sabemos cómo lograr resultados.
          </li>
          <li>
            Enfoque Individual: Desarrollamos estrategias adaptadas a las
            características específicas de tu negocio.
          </li>
          <li>
            Equipo de Expertos: Nuestros profesionales están siempre listos para
            abordar las tareas más desafiantes.
          </li>
          <li>
            Resultados: Nos esforzamos por un crecimiento continuo de tu
            presencia en línea y ganancias.
          </li>
        </ul>
        <p>
          Confía en nosotros para tu éxito en línea. ¡Contáctanos hoy y
          comencemos a promocionar tu sitio web hacia nuevas alturas!
        </p>
      </div>

      <div className="footer">
        <p>&copy; {new Date().getFullYear()} Promote Me</p>
        <LangMenu />
      </div>
    </div>
  );
}

export default RuMain;
