import React from "react";
import MaskedInput from "react-text-mask";

function DigitalMaskedInput(props) {
  var regexMask = "";
  switch (props.mask) {
    case "____ ______":
      regexMask = [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
      break;
    case "___-___":
      regexMask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];
      break;
    case "____-____-____":
      regexMask = [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
      break;
    case "___-___-___ __":
      regexMask = [
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
      ];
      break;
    case "__.__.____":
      regexMask = [
        /[0,1,2,3]/,
        /\d/,
        ".",
        /[0, 1]/,
        /\d/,
        ".",
        /[1, 2]/,
        /\d/,
        /\d/,
        /\d/,
      ];
      break;
  }

  return (
    <div>
      <MaskedInput
        name={props.name}
        id={props.name}
        type="text"
        className={props.className + " input-masked"}
        placeholder={props.mask}
        mask={regexMask}
      />
    </div>
  );
}

export default DigitalMaskedInput;
