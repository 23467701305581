import { useCookies } from "react-cookie";
import SRV from "../../../settings/settings.json";

function LogOut() {
  const lang = "ru";
  const [cookies, setCookie, removeCookie] = useCookies([
    "login_token",
    "pass_token",
  ]);

  for (const c of ["login_token", "pass_token", "role"]) {
    if (Object.keys(cookies).includes(c)) {
      removeCookie(c, { path: "/" });
    }
  }
  window.location.replace(SRV.SITE_URL + lang + "/");

  return null;
}

export default LogOut;
