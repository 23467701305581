import React from "react";
import Footer from "../../footer";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import CONST from "../../settings.json";
import SRV from "../../../settings/settings.json";
import Menu from "./menu";
import Header from "../header";

function Page() {
  const [data, setData] = useState({
    loading: true,
    clients: [],
  });
  const [profile] = useState({});
  const [cookies] = useCookies(["login_token", "role"]);
  useEffect(() => {
    if (Object.keys(profile).length === 0) {
      const resp = axios
        .post(
          SRV.SERVER_URL,
          {
            page: "managerDataRequest",
            request: "['main']",
            login_token: cookies["login_token"],
          },
          CONST.HEADERS
        )
        .then((response) => {
          if (response.data.errors.includes("LOGIN_TOKEN_ERROR")) {
            console.log("LOGIN_TOKEN_ERROR, redirecting..");
            window.location.replace(SRV.SITE_URL + "ru/login");
          }
          console.log(response.data);
          setData(response.data);
        });
    }
  }, []);

  if (!Object.keys(cookies).includes("login_token")) {
    window.location.replace(SRV.SITE_URL + "login");
  }

  var even = false;
  function switch_row() {
    even = !even;
    if (even) {
      return "even";
    }
  }

  return (
    <div className="page">
      <Header />
      <div className="content-manager">
        <Menu structure={CONST.MANAGER_MENU} />
        <div className="container-block container-block-manager">
          <div className="block">
            <p>Клиенты:</p>
            <table>
              <tbody>
                {data.clients.map((w) => (
                  <tr key={"lastwrk-" + w.id} className={switch_row()}>
                    <td>{w.name}</td>
                    <td>{w.site}</td>
                    <td>
                      <a href={"/client_profile/" + w.id}>Профиль</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Page;
