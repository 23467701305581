import React from "react";
import Logo from "../logo";

function Header() {
  return (
    <div className="header">
      <div className="logo">
        <a href="/ru/">
          <Logo t="img" />
          <Logo t="text" />
        </a>
      </div>
    </div>
  );
}

export default Header;
