function Sites() {
  return (
    <div id="sites">
      <h1>Разработка SEO-оптимизированных сайтов</h1>
      <p>
        Получите качественные и недорогие сайты, которые помогут вашему бизнесу
        процветать в онлайн-мире. Мы специализируемся на создании
        профессиональных веб-сайтов, доступных для предпринимателей, стартапов и
        малых бизнесов.
      </p>

      <h2>Разрабатываем сайты недорого:</h2>
      <p>
        Мы понимаем, что для многих малых бизнесов бюджет ограничен. Поэтому мы
        предоставляем доступные цены на разработку сайтов без ущерба для
        качества.
      </p>

      <h2>Сайты оптимизированы для SEO</h2>
      <p>
        Мы уделяем внимание оптимизации для поисковых систем, чтобы ваш сайт был
        легко найден вашими клиентами.
      </p>

      <h2>Легкое управление</h2>
      <p>
        Мы используем удобные системы управления контентом, которые позволят вам
        легко обновлять и редактировать ваш сайт.
      </p>

      <h2>Поддержка и обслуживание</h2>
      <p>
        Мы не просто создаем сайт и уходим. Мы предоставляем поддержку и
        обслуживание, чтобы ваш сайт всегда работал без сбоев.
      </p>

      <p>
        Познакомьтесь с нами и давайте создадим ваш идеальный веб-сайт, который
        поможет вам успешно продвигаться в цифровой эпохе.
      </p>
    </div>
  );
}

export default Sites;
