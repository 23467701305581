import "../../styles/forms.css";
import React, { useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import GetInput from "./getInput";
import CONST from "../settings.json";
import SRV from "../../settings/settings.json";

function Form(props) {
  const [cookies, setCookie] = useCookies(["login_token", "pass_token"]);
  const [errors, setErrors] = useState([]);
  const [showAdr, setshowAdr] = useState(true);

  function toggleAdr() {
    setshowAdr((prev) => !prev);
  }

  function checkForm(form) {
    const REGEXP = {
      email: /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/,
      f: /(^[а-яА-ЯёЁ -]{2,42}$)/,
      i: /(^[а-яА-ЯёЁ -]{2,42}$)/,
      o: /(^[а-яА-ЯёЁ -]{0,42}$)/,
      phone: /(^[\d +\-\(\)]{18}$)/,
      contactPhone: /(^[\d +\-\(\)]{18}$)/,
      password: /(^.{8,42}$)/,
      pass_token: /(^[0-9a-f]{60,70}$)/,
      login_token: /(^[0-9a-f]{60,70}$)/,
      citizenship: /(^[а-яА-ЯёЁ -]{2,22}$)/,
      passIssued: /(^[а-яА-ЯёЁ -№]{8,99}$)/,
      passNum: /(^\d{4} \d{6}$)/,
      passIssuedCode: /(^\d{3}\-\d{3}$)/,
      inn: /(^\d{4}\-\d{4}-\d{4}$)/,
      snils: /(^\d{3}\-\d{3}-\d{3} \d{2}$)/,
      adrRes: /(^[\dа-яА-ЯёЁ -\.\,№]{10,255}$)/,
      adrReg: /(^[\dа-яА-ЯёЁ -\.\,№]{10,255}$)/,
      birthDate: /(^\d{4}\-\d{2}-\d{2}$)/,
      passDate: /(^\d{4}\-\d{2}-\d{2}$)/,
      contactName: /(^[а-яА-ЯёЁ -]{3,42}$)/,
      employerName: /(^[а-яА-ЯёЁ\d"' -]{5,99}$)/,
    };
    var errors = [];

    for (const inp in REGEXP) {
      if (form[inp]) {
        if (!REGEXP[inp].test(form[inp].value)) {
          errors.push(inp);
        }
      }
    }
    if (form["adrSame"]) {
      if (form["adrSame"].checked) {
        errors = errors.filter((item) => item !== "adrRes");
      }
    }

    for (const inp of ["agreePersData", "agreeOferta"]) {
      if (form[inp]) {
        if (!form[inp].checked) {
          errors.push(inp);
        }
      }
    }

    return errors;
  }

  function nextPage(e) {
    const lang = "ru";
    e.preventDefault();
    const errors = checkForm(e.target.elements);
    setErrors(errors);
    const post = {};
    let next = "";
    // creates object to post
    for (const element of e.target.elements) {
      if (element.name) {
        post[element.name] =
          element.type === "checkbox" ? element.checked : element.value;
      }
    }

    if (errors.length === 0) {
      axios.post(SRV.SERVER_URL, post, CONST.HEADERS).then((response) => {
        console.log(response.data);
        if (response.data.login_token) {
          setCookie("login_token", response.data.login_token, { path: "/" });
        }
        if (response.data.pass_token) {
          setCookie("pass_token", response.data.pass_token, { path: "/" });
          window.location.replace(SRV.SITE_URL + props.structure.next);
        }
        if (response.data.errors.length > 0) {
          console.log(response.data.errors);
        } else {
          if (props.structure.next) {
            next = props.structure.next;
          }
          if (response.data.role) {
            next = response.data.role;
          }
          window.location.replace(SRV.SITE_URL + lang + "/" + next);
        }
      });
    }
  }

  return (
    <div className="form-container">
      <form onSubmit={nextPage}>
        <input type="hidden" name="page" value={props.structure.name} />
        {props.structure.inputs.map((input) => (
          <div
            key={"form-div-" + input.name}
            className={"form-div-" + input.type + " " + showAdr}
          >
            <GetInput
              input={input}
              errors={errors}
              cookies={cookies}
              toggleFun={toggleAdr}
              hide={input.name === "adrRes" ? !showAdr : false}
            />
          </div>
        ))}
      </form>
    </div>
  );
}

export default Form;
