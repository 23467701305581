import React from "react";

function DateInput(props) {
  return (
    <input
      type="text"
      id={props.name}
      name={props.name}
      className={props.className}
    />
  );
}

export default DateInput;
