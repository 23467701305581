import React from "react";
import LangMenu from "../LangMenu";
import Logo from "../logo";
import { Helmet } from "react-helmet";

function EnMain(props) {
  return (
    <div className="page">
      <Helmet>
        <html lang="en"></html>
        <title>PROMOTE ME - Website Promotion</title>
        <meta name="description" content="Website promotion" />
        <meta name="keywords" content="promotion, websites, SEO" />
      </Helmet>
      <div className="container">
        <div className="logo">
          <Logo t="img" />
          <Logo t="text" />
        </div>
        <h1>Welcome to PROMOTE ME!</h1>
        {console.log(props)}

        <p>We are your reliable partner in the world of digital promotion.</p>
        <p>
          Our mission is to help your website rise to the top in search engines,
          attract a vast number of visitors, and increase your online profits.
        </p>
        <h2>What we do:</h2>
        <h3>Search Engine Optimization (SEO)</h3>
        <p>
          We know how to make search engines fall in love with your website. Our
          experts develop SEO strategies that enhance your site's visibility and
          lead to organic traffic growth.
        </p>
        <h3>Pay-Per-Click Advertising</h3>
        <p>
          Instant results for your advertising campaigns are what we excel at.
          We create and optimize advertising campaigns in Google Ads and
          Yandex.Direct to attract those looking for your product or service.
        </p>
        <h3>Social Media</h3>
        <p>
          We don't just manage your social media accounts; we create engaging
          content and strategies that make your audience loyal and active.
        </p>
        <h3>Content Marketing</h3>
        <p>
          Content is king. Our creative writers and designers craft content that
          grabs attention and tells your story.
        </p>
        <h3>Analytics and Reports</h3>
        <p>
          We work based on data. Our analysts track and analyze results to
          ensure constant improvement of your online strategy.
        </p>

        <h2>Why PROMOTE ME?</h2>
        <ul>
          <li>
            Experience: We have successfully promoted websites for many years
            and know how to achieve results.
          </li>
          <li>
            Individual Approach: We develop strategies tailored to the specific
            characteristics of your business.
          </li>
          <li>
            Team of Experts: Our professionals are always ready to tackle the
            most challenging tasks.
          </li>
          <li>
            Results: We strive for continuous growth in your online presence and
            profits.
          </li>
        </ul>
        <p>
          Entrust your online success to us. Contact us today, and let's start
          promoting your website to new heights!
        </p>
      </div>

      <div className="footer">
        <p>&copy; {new Date().getFullYear()} Promote Me</p>
        <LangMenu />
      </div>
    </div>
  );
}

export default EnMain;
